import { useState, useEffect, useRef } from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import HelmetIndex from '../helmets/HelmetIndex';
import Intro from '../components/Intro';
import Projects from '../components/Projects';
import Blog from '../components/Blog';
import Contact from '../components/Contact';
import Spacer from '../components/Spacer';
import WithTransition from '../components/WithTransition';

function MainPage({ updateActiveSection, currScrollPos, rememberScrollPos }) {
    const refIntro = useRef(null);
    const refProjects = useRef(null);
    const refBlog = useRef(null);
    const refContact = useRef(null);

    const [offsets, setOffsets] = useState([]);
    const innerHeight = window.innerHeight;

    useEffect(() => {
        window.scrollTo(0, currScrollPos);
    }, [currScrollPos]);

    useScrollPosition(({ prevPos, currPos }) => {
        if (offsets.length === 0) {
            const sectionOffsets = [refIntro, refProjects, refBlog, refContact].map(section => Math.abs(section.current.offsetTop) - innerHeight / 2);
            sectionOffsets.push(2 * refContact.current.offsetTop);
            setOffsets(sectionOffsets);
            findActiveSectionIndex(sectionOffsets, -currPos.y);
        } else {
            findActiveSectionIndex(offsets, -currPos.y);
        }
    }, []);

    function findActiveSectionIndex(offsetsArr, y) {
        const activeSectionIndex = offsetsArr.findIndex(offset => offset > y) - 1;
        updateActiveSection(activeSectionIndex);
    }

    return (
        <WithTransition>
            <main>
                <HelmetIndex />
                <Intro ref={refIntro} />
                <Spacer gradient="yellow-pink" />
                <Projects ref={refProjects} rememberScrollPos={rememberScrollPos} />
                <Spacer gradient="pink-cyan" />
                <Blog ref={refBlog} />
                <Spacer gradient="cyan-green" />
                <Contact ref={refContact} />
            </main>
        </WithTransition>
    );
}

export default MainPage;
