import HelmetMusicMemory from '../helmets/HelmetMusicMemory';
import Octocat from '../components/Octocat';
import MusicMemory from '../projects/projectMusicMemory/MusicMemory';

function ProjectMusicMemory() {
    return (
        <main>
            <HelmetMusicMemory />
            <div className="section-indicator pink"></div>
            <div className="wrapper">
                <div className="project-description">
                    <h1>Music Memory</h1>
                    <p className="lead">Learn chords or play for fun</p>
                </div>
            </div>
            <MusicMemory />
            <div className="wrapper">
                <div className="project-description">
                    <h2 className="border">About the game</h2>
                    <h3>How to play</h3>
                    <p>
                        This Memory game (also known as 'Concentration') combines visuals and sound. The goal is to find all matching piano and/or
                        guitar chords.
                    </p>
                    <p>
                        Four different types of cards are available, and you can choose in the settings which cards you'd like to match against each
                        other. The four card types are:
                    </p>
                    <ul>
                        <li>Chord name</li>
                        <li>Guitar fretboard</li>
                        <li>Music sheet with root note</li>
                        <li>Piano keys</li>
                    </ul>
                    <p>
                        Flipped cards can be clicked/tapped again to play the chord several times. After all cards are found, you can sort the cards
                        and keep playing with the chords you discovered, or start a new round.
                    </p>
                    <p>
                        The game can be played to learn guitar and piano chords, to train the musical ear (by turning off the colour guide), or just
                        for fun.
                    </p>
                    <h3>Motivation</h3>
                    <p>
                        This game was originally my entry for the 2020{' '}
                        <a href="https://js13kgames.com/" target="_blank" rel="noreferrer">
                            js13kgames competition
                        </a>
                        . The rules are to write a stand-alone browser game (HTML/CSS/JavaScript) with maximum 13 kilobytes, no external resources.
                        Theme in 2020 was "404 not found".
                    </p>
                    <p>
                        The game scored 3rd place in category audio, 37th place in category mobile (81 entries total), and 84th place in category
                        desktop (212 entries total). Having participated in such a competition for the first time, this was quite an amazing outcome
                        and experience.
                    </p>
                    <p>
                        Link to the original game and source code:{' '}
                        <a href="https://js13kgames.com/entries/music-memory" target="_blank" rel="noreferrer">
                            https://js13games.com/entries/music-memory
                        </a>
                    </p>
                    <h3>Tech</h3>
                    <p>
                        I have since re-written the game in{' '}
                        <a href="https://reactjs.org/" target="_blank" rel="noreferrer">
                            ReactJS
                        </a>
                        . The UI is mostly the same, but the sound quality and cross-browser compatibility is considerably improved by using{' '}
                        <a href="https://surikov.github.io/webaudiofont/" target="_blank" rel="noreferrer">
                            WebAudioFont
                        </a>{' '}
                        in this version.
                    </p>
                    <p>
                        Repository:{' '}
                        <a href="https://github.com/jsdisco/projects-musicmemory" target="_blank" rel="noreferrer">
                            github.com/jsdisco/projects-musicmemory
                        </a>
                    </p>
                </div>
            </div>
            <Octocat url="https://github.com/jsdisco/projects-musicmemory" />
        </main>
    );
}

export default ProjectMusicMemory;
