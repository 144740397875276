import { forwardRef } from 'react';

import Dancefloor from './Dancefloor';

const Intro = forwardRef((props, ref) => {
    return (
        <section id="intro" ref={ref}>
            <div className="section-indicator yellow"></div>
            <div className="wrapper">
                <h1>
                    <span className="h1-top">
                        <span className="h1-1a">Full Stack Web</span>
                        <span className="h1-1b">Mobile App</span>
                        <span className="h1-2">Developer</span>
                    </span>
                </h1>
            </div>
            <Dancefloor section="intro" />
        </section>
    );
});

export default Intro;
