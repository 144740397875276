export const sheetSVG = {
    C:
        'M241.8,305.46c0,4.56-4.54,10-15.44,10s-18.16-6.39-18.16-11.87,9.08-9.13,16.35-9.13C233.63,294.5,241.8,300.89,241.8,305.46Zm-15.44,8.21c3.63,0,5.45-3.65,5.45-7.3s-2.72-9.13-7.26-9.13c-2.73,0-6.36,3.19-6.36,6.85S220.91,313.67,226.36,313.67Z',
    D:
        'M241.8,293c0,4.56-4.54,10-15.44,10s-18.16-6.39-18.16-11.87,9.08-9.13,16.35-9.13C233.63,282,241.8,288.39,241.8,293Zm-15.44,8.21c3.63,0,5.45-3.65,5.45-7.3s-2.72-9.13-7.26-9.13c-2.73,0-6.36,3.19-6.36,6.85S220.91,301.17,226.36,301.17Z',
    E:
        'M241.8,280.46c0,4.56-4.54,10-15.44,10s-18.16-6.39-18.16-11.87,9.08-9.13,16.35-9.13C233.63,269.5,241.8,275.89,241.8,280.46Zm-15.44,8.21c3.63,0,5.45-3.65,5.45-7.3s-2.72-9.13-7.26-9.13c-2.73,0-6.36,3.19-6.36,6.85S220.91,288.67,226.36,288.67Z',
    F:
        'M241.8,268c0,4.56-4.54,10-15.44,10s-18.16-6.39-18.16-11.87,9.08-9.13,16.35-9.13C233.63,257,241.8,263.39,241.8,268Zm-15.44,8.21c3.63,0,5.45-3.65,5.45-7.3s-2.72-9.13-7.26-9.13c-2.73,0-6.36,3.19-6.36,6.85S220.91,276.17,226.36,276.17Z',
    G:
        'M241.8,255.46c0,4.56-4.54,10-15.44,10s-18.16-6.39-18.16-11.87,9.08-9.13,16.35-9.13C233.63,244.5,241.8,250.89,241.8,255.46Zm-15.44,8.21c3.63,0,5.45-3.65,5.45-7.3s-2.72-9.13-7.26-9.13c-2.73,0-6.36,3.19-6.36,6.85S220.91,263.67,226.36,263.67Z',
    A:
        'M241.8,243c0,4.56-4.54,10-15.44,10s-18.16-6.39-18.16-11.87,9.08-9.13,16.35-9.13C233.63,232,241.8,238.39,241.8,243Zm-15.44,8.21c3.63,0,5.45-3.65,5.45-7.3s-2.72-9.13-7.26-9.13c-2.73,0-6.36,3.19-6.36,6.85S220.91,251.17,226.36,251.17Z',
    B:
        'M241.8,230.46c0,4.56-4.54,10-15.44,10s-18.16-6.39-18.16-11.87,9.08-9.13,16.35-9.13C233.63,219.5,241.8,225.89,241.8,230.46Zm-15.44,8.21c3.63,0,5.45-3.65,5.45-7.3s-2.72-9.13-7.26-9.13c-2.73,0-6.36,3.19-6.36,6.85S220.91,238.67,226.36,238.67Z',
    'C#':
        'M190,293v-7.5l-5,1.82V275.5l-2,.73V288l-7,2.55V278.5l-2,.73V291.3l-4,1.45v7.5l4-1.45v17.5l-4,1.45v7.5l4-1.45v12.43l2-.73V323.07l7-2.55v12.71l2-.73V319.79l5-1.82v-7.5l-5,1.82v-17.5Zm-7,20-7,2.55v-17.5l7-2.55Z',
    'D#':
        'M190,280.47V273l-5,1.82V263l-2,.73v11.79l-7,2.55V266l-2,.73V278.8l-4,1.45v7.5l4-1.45v17.5l-4,1.45v7.5l4-1.45v12.43l2-.73V310.57l7-2.55v12.71l2-.73V307.29l5-1.82V298l-5,1.82v-17.5Zm-7,20-7,2.55v-17.5l7-2.55Z',
    'F#':
        'M190,255.47V248l-5,1.82V238l-2,.73v11.79l-7,2.55V241l-2,.73V253.8l-4,1.45v7.5l4-1.45v17.5l-4,1.45v7.5l4-1.45v12.43l2-.73V285.57l7-2.55v12.71l2-.73V282.29l5-1.82V273l-5,1.82v-17.5Zm-7,20-7,2.55v-17.5l7-2.55Z',
    'G#':
        'M190,243v-7.5l-5,1.82V225.5l-2,.73V238l-7,2.55V228.5l-2,.73V241.3l-4,1.45v7.5l4-1.45v17.5l-4,1.45v7.5l4-1.45v12.43l2-.73V273.07l7-2.55v12.71l2-.73V269.79l5-1.82v-7.5l-5,1.82v-17.5Zm-7,20-7,2.55v-17.5l7-2.55Z',
    'A#':
        'M190,230.47V223l-5,1.82V213l-2,.73v11.79l-7,2.55V216l-2,.73V228.8l-4,1.45v7.5l4-1.45v17.5l-4,1.45v7.5l4-1.45v12.43l2-.73V260.57l7-2.55v12.71l2-.73V257.29l5-1.82V248l-5,1.82v-17.5Zm-7,20.05-7,2.55v-17.5l7-2.55Z',
    Db:
        'M177.71,300A68.74,68.74,0,0,0,186.5,292a9.41,9.41,0,0,0,2.36-6.22,6.52,6.52,0,0,0-2.15-5.25,8,8,0,0,0-5.35-1.82,12,12,0,0,0-8.15,3.21V251.75H170v55.5A72,72,0,0,1,177.71,300Zm-4.5-14.35a10.15,10.15,0,0,1,3.11-2.57,7.86,7.86,0,0,1,3.32-.65,4.71,4.71,0,0,1,3.32,1.29,4.2,4.2,0,0,1,1.4,3.21q0,2.58-3.54,6.43a64.32,64.32,0,0,1-7.61,7.07Z',
    Eb:
        'M177.71,287.46a68.74,68.74,0,0,0,8.79-7.92,9.41,9.41,0,0,0,2.36-6.22,6.52,6.52,0,0,0-2.15-5.25,8,8,0,0,0-5.35-1.82,12,12,0,0,0-8.15,3.21V239.25H170v55.5A72,72,0,0,1,177.71,287.46Zm-4.5-14.35a10.15,10.15,0,0,1,3.11-2.57,7.86,7.86,0,0,1,3.32-.65,4.71,4.71,0,0,1,3.32,1.29,4.2,4.2,0,0,1,1.4,3.21q0,2.58-3.54,6.43a64.32,64.32,0,0,1-7.61,7.07Z',
    Gb:
        'M177.71,262.46a68.74,68.74,0,0,0,8.79-7.92,9.41,9.41,0,0,0,2.36-6.22,6.52,6.52,0,0,0-2.15-5.25,8,8,0,0,0-5.35-1.82,12,12,0,0,0-8.15,3.21V214.25H170v55.5A72,72,0,0,1,177.71,262.46Zm-4.5-14.35a10.15,10.15,0,0,1,3.11-2.57,7.86,7.86,0,0,1,3.32-.65,4.71,4.71,0,0,1,3.32,1.29,4.2,4.2,0,0,1,1.4,3.21q0,2.58-3.54,6.43a64.32,64.32,0,0,1-7.61,7.07Z',
    Ab:
        'M177.71,250A68.74,68.74,0,0,0,186.5,242a9.41,9.41,0,0,0,2.36-6.22,6.52,6.52,0,0,0-2.15-5.25,8,8,0,0,0-5.35-1.82,12,12,0,0,0-8.15,3.21V201.75H170v55.5A72,72,0,0,1,177.71,250Zm-4.5-14.35a10.15,10.15,0,0,1,3.11-2.57,7.86,7.86,0,0,1,3.32-.65,4.71,4.71,0,0,1,3.32,1.29,4.2,4.2,0,0,1,1.4,3.21q0,2.58-3.54,6.43a64.32,64.32,0,0,1-7.61,7.07Z',
    Bb:
        'M177.71,237.46a68.74,68.74,0,0,0,8.79-7.92,9.41,9.41,0,0,0,2.36-6.22,6.52,6.52,0,0,0-2.15-5.25,8,8,0,0,0-5.35-1.82,12,12,0,0,0-8.15,3.21V189.25H170v55.5A72,72,0,0,1,177.71,237.46Zm-4.5-14.35a10.15,10.15,0,0,1,3.11-2.57,7.86,7.86,0,0,1,3.32-.65,4.71,4.71,0,0,1,3.32,1.29,4.2,4.2,0,0,1,1.4,3.21q0,2.58-3.54,6.43a64.32,64.32,0,0,1-7.61,7.07Z'
};
