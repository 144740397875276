import HelmetLilHelper from '../helmets/HelmetLilHelper';
import Octocat from '../components/Octocat';
import WithTransition from '../components/WithTransition';

function ProjectLilHelper() {
    return (
        <WithTransition>
            <main>
                <HelmetLilHelper />
                <div className="section-indicator pink"></div>
                <div className="wrapper">
                    <div className="project-description">
                        <h1>lilHelper</h1>
                        <p className="lead">An Android app for my personal use</p>
                    </div>
                </div>
                <div className="wrapper">
                    <img className="img-project-preview" src="../img/project-preview-lilhelper.jpg" alt="project preview for lilHelper app" />
                </div>
                <div className="wrapper">
                    <div className="project-description">
                        <h2 className="border">About the App</h2>
                        <h3>Introduction</h3>
                        <p>
                            As the name suggests, this app is my little helper for everyday tasks. I can create/read/update/delete notes and drawings,
                            and it has (read-only) access to my recipe database via an API call to my web server.
                        </p>
                        <h3>Motivation</h3>
                        <p>
                            As a junior Android developer, I needed a project for my portfolio - so I built an app that I would actually use. It gave
                            me the chance to cover a number of important topics, including API calls (Retrofit library) and database operations (Room
                            library).
                        </p>
                        <h3>Tech</h3>
                        <p>
                            The app was built with{' '}
                            <a href="https://developer.android.com/studio/" target="_blank" rel="noreferrer">
                                Android Studio
                            </a>{' '}
                            (written in{' '}
                            <a href="https://kotlinlang.org/" target="_blank" rel="noreferrer">
                                Kotlin
                            </a>
                            ).
                        </p>
                        <p>
                            Repository:{' '}
                            <a href="https://github.com/jsdisco/projects-lil-helper" target="_blank" rel="noreferrer">
                                github.com/jsdisco/projects-lil-helper
                            </a>
                        </p>
                    </div>
                </div>
                <Octocat url="https://github.com/jsdisco/projects-lil-helper" />
            </main>
        </WithTransition>
    );
}

export default ProjectLilHelper;
