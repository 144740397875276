import { useLocation } from 'react-router-dom';

function Footer() {
    const location = useLocation();

    return (
        <footer>
            <div className={location.pathname === '/' ? 'section-indicator green' : 'section-indicator pink'}></div>
            <div className="wrapper">
                <div className="footer">
                    <div>
                        <span>jsdisco&nbsp;</span>
                        <span>&copy;</span>
                        <span>2023</span>
                    </div>
                    <div className="footer-contact">
                        <a href="https://github.com/jsdisco" className="img-link" target="_blank" rel="noreferrer">
                            <img src="img/social-github-white.png" alt="jsdisco on github" width="30" height="30" />
                        </a>
                        <a href="https://hashnode.com/@jsdisco" className="img-link" target="_blank" rel="noreferrer">
                            <img src="img/logo-hashnode-white.svg" alt="jsdisco on hashnode" width="30" height="30" />
                        </a>
                        <a href="https://www.linkedin.com/in/jsdisco/" className="img-link" target="_blank" rel="noreferrer">
                            <img src="img/linkedin.svg" alt="jsdisco on linkedin" width="30" height="30" />
                        </a>
                        <a href="https://twitter.com/jsdisco" className="img-link" target="_blank" rel="noreferrer">
                            <img src="img/twitter_blue_60.svg" alt="jsdisco on twitter" width="30" height="30" />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
