import { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { BsArrowLeft } from 'react-icons/bs';

function Nav({ activeSection }) {
    const [isNavOpen, setIsNavOpen] = useState(false);

    const toggleNav = () => setIsNavOpen(prev => !prev);

    const location = useLocation();

    return (
        <nav>
            {location.pathname === '/' ? (
                <div className="wrapper nav-icon-container">
                    <div className={isNavOpen ? 'nav-icon open' : 'nav-icon'} onClick={toggleNav}>
                        <div className="nav-icon-inner">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="nav-icon-back">
                    <Link to="/">
                        <BsArrowLeft size="1.5em" />
                    </Link>
                </div>
            )}

            {location.pathname === '/' && (
                <div className="wrapper">
                    <ul className={isNavOpen ? 'ul-main open' : 'ul-main'}>
                        <li className={activeSection === 0 && location.pathname === '/' ? 'nav-intro active' : 'nav-intro'}>
                            <HashLink smooth to="/#intro">
                                Home
                            </HashLink>
                        </li>
                        <li className="spacer">
                            <span></span>
                        </li>
                        <li className={activeSection === 1 && location.pathname === '/' ? 'nav-projects active' : 'nav-projects'}>
                            <HashLink smooth to="/#projects">
                                Projects
                            </HashLink>
                        </li>
                        <li className="spacer">
                            <span></span>
                        </li>
                        <li className={activeSection === 2 && location.pathname === '/' ? 'nav-blog active' : 'nav-blog'}>
                            <HashLink smooth to="/#blog">
                                Blog
                            </HashLink>
                        </li>
                        <li className="spacer">
                            <span></span>
                        </li>
                        <li className={activeSection === 3 && location.pathname === '/' ? 'nav-contact active' : 'nav-contact'}>
                            <HashLink smooth to="/#contact">
                                Contact
                            </HashLink>
                        </li>
                    </ul>
                </div>
            )}
        </nav>
    );
}

export default Nav;
