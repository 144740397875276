import React, { useState, useEffect, useCallback } from 'react';
import { notes } from '../utils/constants.js';

function Midi({ showChannels, playNoteCallback, updateBtnsCallback }) {
    const [msg, setMsg] = useState(null);

    const midiCallback = useCallback(() => {
        function addMidi() {
            let midi;

            if (navigator.requestMIDIAccess) {
                navigator
                    .requestMIDIAccess({
                        sysex: true
                    })
                    .then(onMIDISuccess, onMIDIFailure);
            } else {
                alert('No MIDI support in your browser.');
            }

            function onMIDISuccess(midiAccess) {
                midi = midiAccess;
                const inputs = midi.inputs.values();
                for (let input = inputs.next(); input && !input.done; input = inputs.next()) {
                    input.value.onmidimessage = onMIDIMessage;
                }
            }

            function onMIDIFailure(e) {
                console.log("No access to MIDI devices or your browser doesn't support WebMIDI API");
            }
        }
        function onMIDIMessage(event) {
            const msg = event.data;
            //const cmd = +msg[0] >> 4;
            //const channel = +msg[0] & 0xf;
            let command;
            let midiKey = null;
            let vel;
            if (isNaN(+msg[2])) {
                command = +msg[0];
                vel = +msg[1];
            } else {
                command = +msg[0];
                midiKey = +msg[1];
                vel = +msg[2];
            }

            if (midiKey && command >= 144 && command < 160 && vel > 0) {
                playNoteCallback(midiKey);
            }
            if (midiKey) {
                const note = notes[midiKey % 12];
                const octave = Math.floor(midiKey / 12) - 1;
                const name = `${note}-${octave}-${midiKey}`;
                const id = `${note}-${octave}-${command}`;
                if (vel > 0 && command >= 144 && command < 160) {
                    updateBtnsCallback(name, id, 'down');
                } else if (command === 128 || vel === 0) {
                    updateBtnsCallback(name, id, 'midiUp');
                }
            }
            setMsg({ command, midiKey, vel });
        }
        addMidi();
    }, [playNoteCallback, updateBtnsCallback]);

    useEffect(() => midiCallback(), [midiCallback]);

    return (
        <div className={showChannels ? 'midi visible' : 'midi'}>
            <h4>MIDI</h4>
            <div>
                <p>command: {msg && msg.command}</p>
                <p>midiKey: {msg && msg.midiKey}</p>
            </div>
        </div>
    );
}

export default Midi;
