import HelmetHangman from '../helmets/HelmetHangman';
import Octocat from '../components/Octocat';
import Hangman from '../projects/projectHangman/Hangman';
import WithTransition from '../components/WithTransition';

function ProjectHangman() {
    return (
        <WithTransition>
            <main>
                <HelmetHangman />
                <div className="section-indicator pink"></div>
                <div className="wrapper">
                    <div className="project-description">
                        <h1>Hangman</h1>
                        <p className="lead">Don't hang the stick man</p>
                    </div>
                </div>
                <Hangman />
                <div className="wrapper">
                    <div className="project-description">
                        <h2 className="border">About the Game</h2>
                        <h3>How to play</h3>
                        <p>
                            This is a fairly straightforward Hangman game clone. Click/tap on the letters. Find all words before the stickman drawing
                            is complete.
                        </p>
                        <h3>Motivation</h3>
                        <p>
                            I wrote this game while experimenting with dynamically changing the contents of SVGs in React. An entertaining Sunday
                            afternoon challenge.
                        </p>
                        <h3>Tech</h3>
                        <p>
                            The game was built with{' '}
                            <a href="https://reactjs.org/" target="_blank" rel="noreferrer">
                                ReactJS
                            </a>
                            . SVG made with{' '}
                            <a href="https://excalidraw.com/" target="_blank" rel="noreferrer">
                                excalidraw
                            </a>
                            .
                        </p>
                        <p>
                            Repository:{' '}
                            <a href="https://github.com/jsdisco/projects-hangman" target="_blank" rel="noreferrer">
                                github.com/jsdisco/projects-hangman
                            </a>
                        </p>
                    </div>
                </div>
                <Octocat url="https://github.com/jsdisco/projects-hangman" />
            </main>
        </WithTransition>
    );
}

export default ProjectHangman;
