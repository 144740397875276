export const chordNotes = {
    C: {
        7: ['C-4', 'E-4', 'A#-4', 'C-5', 'E-5'],
        major: ['C-4', 'E-4', 'G-4', 'C-5', 'E-5'],
        minor: ['C-4', 'D#-4', 'G-4', 'C-5', 'G-5'],
        m7: ['C-4', 'D#-4', 'A#-4', 'D#-5'],
        maj7: ['G-3', 'C-4', 'E-4', 'G-4', 'B-4', 'E-5'],
        sus4: ['C-4', 'F-4', 'G-4', 'C-5', 'F-5'],
        dim: ['C-4', 'D#-4', 'C-5', 'F#-5'],
        dim7: ['D#-4', 'A-4', 'C-5', 'F#-5'],
        aug: ['C-4', 'E-4', 'G#-4', 'C-5']
    },
    'C#': {
        7: ['C#-4', 'F-4', 'B-4', 'C#-5'],
        major: ['C#-4', 'F-4', 'G#-4', 'C#-5', 'F-5'],
        minor: ['C#-4', 'E-4', 'G#-4', 'C#-5'],
        m7: ['C#-4', 'G#-4', 'B-4', 'E-5', 'G#-5'],
        maj7: ['C#-4', 'F-4', 'G#-4', 'C-5', 'F-5'],
        sus4: ['C#-4', 'F#-4', 'G#-4', 'C#-5'],
        dim: ['C#-4', 'E-4', 'C#-5', 'G-5'],
        dim7: ['E-4', 'A#-4', 'C#-5', 'G-5'],
        aug: ['C#-4', 'F#-4', 'B-4', 'C#-5', 'F#-5']
    },
    D: {
        7: ['D-4', 'A-4', 'C-5', 'F#-5'],
        major: ['D-4', 'A-4', 'D-5', 'F#-5'],
        minor: ['D-4', 'A-4', 'D-5', 'F-5'],
        m7: ['D-4', 'A-4', 'C-5', 'F-5'],
        maj7: ['D-4', 'A-4', 'C#-5', 'F#-5'],
        sus4: ['D-4', 'A-4', 'D-5', 'G-5'],
        dim: ['D-4', 'G#-4', 'F-5'],
        dim7: ['D-4', 'G#-4', 'B-4', 'F-5'],
        aug: ['D-4', 'A#-4', 'D-5', 'F#-5']
    },
    'D#': {
        7: ['D#-4', 'A#-4', 'C#-5', 'G-5'],
        major: ['D#-4', 'A#-4', 'D#-5', 'G-5'],
        minor: ['D#-4', 'A#-4', 'D#-5', 'F#-5'],
        m7: ['D#-4', 'A#-4', 'C#-5', 'F#-5'],
        maj7: ['A#-3', 'D#-4', 'A#-4', 'D-5', 'G-5'],
        sus4: ['D#-4', 'A#-4', 'D#-5', 'G#-5'],
        dim: ['D#-4', 'A-4', 'F#-5'],
        dim7: ['D#-4', 'A-4', 'C-5', 'F#-5'],
        aug: ['G-4', 'B-4', 'D#-5', 'G-5']
    },
    E: {
        7: ['E-3', 'B-3', 'D-4', 'G#-4', 'B-4', 'E-5'],
        major: ['E-3', 'B-3', 'E-4', 'G#-4', 'B-4', 'E-5'],
        minor: ['E-3', 'B-3', 'E-4', 'G-4', 'B-4', 'E-5'],
        m7: ['E-3', 'B-3', 'E-4', 'G-4', 'D-5', 'E-5'],
        maj7: ['E-3', 'B-3', 'D#-4', 'G#-4', 'B-4', 'E-5'],
        sus4: ['E-3', 'B-3', 'E-4', 'A-4', 'B-4', 'E-5'],
        dim: ['E-4', 'A#-4', 'G-5'],
        dim7: ['E-3', 'A#-3', 'E-4', 'G-4', 'C#-5', 'E-5'],
        aug: ['E-3', 'C-4', 'E-4', 'G#-4', 'C-5', 'E-5']
    },
    F: {
        7: ['F-3', 'C-4', 'D#-4', 'A-4', 'C-5', 'F-5'],
        major: ['F-3', 'C-4', 'F-4', 'A-4', 'C-5', 'F-5'],
        minor: ['F-3', 'C-4', 'F-4', 'G#-4', 'C-5', 'F-5'],
        m7: ['F-3', 'C-4', 'D#-4', 'G#-4', 'C-5', 'F-5'],
        maj7: ['F-4', 'A-4', 'C-5', 'E-5'],
        sus4: ['F-3', 'C-4', 'F-4', 'A#-4', 'C-5', 'F-5'],
        dim: ['F-4', 'B-4', 'G#-5'],
        dim7: ['F-3', 'D-4', 'G#-4', 'B-4', 'F-5'],
        aug: ['F-4', 'A-4', 'C#-5', 'F-5']
    },
    'F#': {
        7: ['F#-3', 'C#-4', 'E-4', 'A#-4', 'C#-5', 'F#-5'],
        major: ['F#-3', 'C#-4', 'F#-4', 'A#-4', 'C#-5', 'F#-5'],
        minor: ['F#-3', 'C#-4', 'F#-4', 'A-4', 'C#-5', 'F#-5'],
        m7: ['F#-3', 'C#-4', 'E-4', 'A-4', 'C#-5', 'F#-5'],
        maj7: ['F#-3', 'C#-4', 'F-4', 'A#-4', 'C#-5', 'F#-5'],
        sus4: ['F#-3', 'C#-4', 'F#-4', 'B-4', 'C#-5', 'F#-5'],
        dim: ['F#-3', 'A-3', 'A-4', 'C-5'],
        dim7: ['F#-3', 'D#-4', 'A-4', 'C-5'],
        aug: ['F#-4', 'A#-4', 'D-5', 'F#-5']
    },
    G: {
        7: ['G-3', 'B-3', 'D-4', 'G-4', 'B-4', 'F-5'],
        major: ['G-3', 'B-3', 'D-4', 'G-4', 'B-4', 'G-5'],
        minor: ['G-3', 'A#-3', 'D-4', 'G-4', 'D-5', 'G-5'],
        m7: ['G-3', 'D-4', 'F-4', 'A#-4', 'D-5', 'G-5'],
        maj7: ['G-3', 'B-3', 'D-4', 'G-4', 'B-4', 'F#-5'],
        sus4: ['G-3', 'C-4', 'D-4', 'G-4', 'C-5', 'G-5'],
        dim: ['G-3', 'A#-3', 'A#-4', 'C#-5'],
        dim7: ['G-3', 'A#-3', 'A#-4', 'C#-5', 'E-5'],
        aug: ['G-3', 'B-3', 'D#-4', 'G-4', 'B-4']
    },
    'G#': {
        7: ['D#-4', 'G#-4', 'C-5', 'F#-5'],
        major: ['G#-3', 'C-4', 'D#-4', 'G#-4', 'C-5'],
        minor: ['G#-3', 'D#-4', 'G#-4', 'B-4', 'D#-5', 'G#-5'],
        m7: ['G#-3', 'D#-4', 'F#-4', 'B-4', 'D#-5', 'G#-5'],
        maj7: ['G#-3', 'D#-4', 'G-4', 'C-5', 'D#-5', 'G#-5'],
        sus4: ['D#-4', 'G#-4', 'C#-5', 'G#-5'],
        dim: ['G#-3', 'B-3', 'B-4', 'D-5'],
        dim7: ['D-4', 'G#-4', 'B-4', 'F-5'],
        aug: ['G#-3', 'C-4', 'E-4', 'G#-4', 'C-5']
    },
    A: {
        7: ['A-3', 'E-4', 'G-4', 'C#-5', 'E-5'],
        major: ['A-3', 'E-4', 'A-4', 'C#-5', 'E-5'],
        minor: ['A-3', 'E-4', 'A-4', 'C-5', 'E-5'],
        m7: ['A-3', 'E-4', 'G-4', 'C-5', 'E-5'],
        maj7: ['A-3', 'E-4', 'G#-4', 'C#-5', 'E-5'],
        sus4: ['A-3', 'E-4', 'A-4', 'D-5', 'E-5'],
        dim: ['A-3', 'D#-4', 'A-4', 'C-5'],
        dim7: ['A-3', 'D#-4', 'A-4', 'C-5', 'F#-5'],
        aug: ['A-3', 'F-4', 'A-4', 'C#-5', 'F-5']
    },
    'A#': {
        7: ['A#-3', 'F-4', 'G#-4', 'D-5', 'F-5'],
        major: ['A#-3', 'F-4', 'A#-4', 'D-5', 'F-5'],
        minor: ['A#-3', 'F-4', 'A#-4', 'C#-5', 'F-5'],
        m7: ['A#-3', 'F-4', 'G#-4', 'C#-5', 'F-5'],
        maj7: ['A#-3', 'F-4', 'A-4', 'D-5', 'F-5'],
        sus4: ['A#-3', 'F-4', 'A#-4', 'D#-5', 'F-5'],
        dim: ['A#-3', 'E-4', 'A#-4', 'C#-5'],
        dim7: ['A#-3', 'E-4', 'G-4', 'C#-5', 'E-5'],
        aug: ['A#-3', 'F#-4', 'A#-4', 'D-5']
    },
    B: {
        7: ['B-3', 'D#-4', 'A-4', 'B-4', 'F#-5'],
        major: ['F#-3', 'B-3', 'F#-4', 'B-4', 'D#-5', 'F#-5'],
        minor: ['F#-3', 'B-3', 'F#-4', 'B-4', 'D-5', 'F#-5'],
        m7: ['F#-3', 'B-3', 'F#-4', 'A-4', 'D-5', 'F#-5'],
        maj7: ['F#-3', 'B-3', 'F#-4', 'A#-4', 'D#-5', 'F#-5'],
        sus4: ['F#-3', 'B-3', 'F#-4', 'B-4', 'E-5', 'F#-5'],
        dim: ['B-3', 'D-4', 'B-4', 'F-5'],
        dim7: ['B-3', 'F-4', 'G#-4', 'D-5', 'F-5'],
        aug: ['B-3', 'D#-4', 'G-4', 'B-4']
    }
};
export const fretPos = {
    C: {
        7: [-1, 3, 2, 3, 1, 0],
        major: [-1, 3, 2, 0, 1, 0],
        minor: [-1, 3, 1, 0, 1, 3],
        m7: [-1, 3, 1, 3, 4, -1],
        maj7: [3, 3, 2, 0, 0, 0],
        sus4: [-1, 3, 3, 0, 1, 1],
        dim: [-1, 3, 1, -1, 1, 2],
        dim7: [-1, -1, 1, 2, 1, 2],
        aug: [-1, 3, 2, 1, 1, -1]
    },
    'C#': {
        7: [-1, 4, 3, 4, 2, -1],
        major: [-1, 4, 3, 1, 2, 1],
        minor: [-1, 4, 2, 1, 2, -1],
        m7: [-1, 4, 6, 4, 5, 4],
        maj7: [-1, 4, 3, 1, 1, 1],
        sus4: [-1, 4, 4, 1, 2, -1],
        dim: [-1, 4, 2, -1, 2, 3],
        dim7: [-1, -1, 2, 3, 2, 3],
        aug: [-1, 4, 4, 4, 2, 2]
    },
    D: {
        7: [-1, -1, 0, 2, 1, 2],
        major: [-1, -1, 0, 2, 3, 2],
        minor: [-1, -1, 0, 2, 3, 1],
        m7: [-1, -1, 0, 2, 1, 1],
        maj7: [-1, -1, 0, 2, 2, 2],
        sus4: [-1, -1, 0, 2, 3, 3],
        dim: [-1, -1, 0, 1, -1, 1],
        dim7: [-1, -1, 0, 1, 0, 1],
        aug: [-1, -1, 0, 3, 3, 2]
    },
    'D#': {
        7: [-1, -1, 1, 3, 2, 3],
        major: [-1, -1, 1, 3, 4, 3],
        minor: [-1, -1, 1, 3, 4, 2],
        m7: [-1, -1, 1, 3, 2, 2],
        maj7: [-1, 1, 1, 3, 3, 3],
        sus4: [-1, -1, 1, 3, 4, 4],
        dim: [-1, -1, 1, 2, -1, 2],
        dim7: [-1, -1, 1, 2, 1, 2],
        aug: [-1, -1, 5, 4, 4, 3]
    },
    E: {
        7: [0, 2, 0, 1, 0, 0],
        major: [0, 2, 2, 1, 0, 0],
        minor: [0, 2, 2, 0, 0, 0],
        m7: [0, 2, 2, 0, 3, 0],
        maj7: [0, 2, 1, 1, 0, 0],
        sus4: [0, 2, 2, 2, 0, 0],
        dim: [-1, -1, 2, 3, -1, 3],
        dim7: [0, 1, 2, 0, 2, 0],
        aug: [0, 3, 2, 1, 1, 0]
    },
    F: {
        7: [1, 3, 1, 2, 1, 1],
        major: [1, 3, 3, 2, 1, 1],
        minor: [1, 3, 3, 1, 1, 1],
        m7: [1, 3, 1, 1, 1, 1],
        maj7: [-1, -1, 3, 2, 1, 0],
        sus4: [1, 3, 3, 3, 1, 1],
        dim: [-1, -1, 3, 4, -1, 4],
        dim7: [1, -1, 0, 1, 0, 1],
        aug: [-1, -1, 3, 2, 2, 1]
    },
    'F#': {
        7: [2, 4, 2, 3, 2, 2],
        major: [2, 4, 4, 3, 2, 2],
        minor: [2, 4, 4, 2, 2, 2],
        m7: [2, 4, 2, 2, 2, 2],
        maj7: [2, 4, 3, 3, 2, 2],
        sus4: [2, 4, 4, 4, 2, 2],
        dim: [2, 0, -1, 2, 1, -1],
        dim7: [2, -1, 1, 2, 1, -1],
        aug: [-1, -1, 4, 3, 3, 2]
    },
    G: {
        7: [3, 2, 0, 0, 0, 1],
        major: [3, 2, 0, 0, 0, 3],
        minor: [3, 1, 0, 0, 3, 3],
        m7: [3, 5, 3, 3, 3, 3],
        maj7: [3, 2, 0, 0, 0, 2],
        sus4: [3, 3, 0, 0, 1, 3],
        dim: [3, 1, -1, 3, 2, -1],
        dim7: [3, 1, -1, 3, 2, 0],
        aug: [3, 2, 1, 0, 0, -1]
    },
    'G#': {
        7: [-1, -1, 1, 1, 1, 2],
        major: [4, 3, 1, 1, 1, -1],
        minor: [4, 6, 6, 4, 4, 4],
        m7: [4, 6, 4, 4, 4, 4],
        maj7: [4, 6, 5, 5, 4, 4],
        sus4: [-1, -1, 1, 1, 2, 4],
        dim: [4, 2, -1, 4, 3, -1],
        dim7: [-1, -1, 0, 1, 0, 1],
        aug: [4, 3, 2, 1, 1, -1]
    },
    A: {
        7: [-1, 0, 2, 0, 2, 0],
        major: [-1, 0, 2, 2, 2, 0],
        minor: [-1, 0, 2, 2, 1, 0],
        m7: [-1, 0, 2, 0, 1, 0],
        maj7: [-1, 0, 2, 1, 2, 0],
        sus4: [-1, 0, 2, 2, 3, 0],
        dim: [-1, 0, 1, 2, 1, -1],
        dim7: [-1, 0, 1, 2, 1, 2],
        aug: [-1, 0, 3, 2, 2, 1]
    },
    'A#': {
        7: [-1, 1, 3, 1, 3, 1],
        major: [-1, 1, 3, 3, 3, 1],
        minor: [-1, 1, 3, 3, 2, 1],
        m7: [-1, 1, 3, 1, 2, 1],
        maj7: [-1, 1, 3, 2, 3, 1],
        sus4: [-1, 1, 3, 3, 4, 1],
        dim: [-1, 1, 2, 3, 2, -1],
        dim7: [-1, 1, 2, 0, 2, 0],
        aug: [-1, 1, 4, 3, 3, -1]
    },
    B: {
        7: [-1, 2, 1, 2, 0, 2],
        major: [2, 2, 4, 4, 4, 2],
        minor: [2, 2, 4, 4, 3, 2],
        m7: [2, 2, 4, 2, 3, 2],
        maj7: [2, 2, 4, 3, 4, 2],
        sus4: [2, 2, 4, 4, 5, 2],
        dim: [-1, 2, 0, -1, 0, 1],
        dim7: [-1, 2, 3, 1, 3, 1],
        aug: [-1, 2, 1, 0, 0, -1]
    }
};
