export const pianoSVG = {
    notesWhite: ['C', 'D', 'E', 'F', 'G', 'A', 'B', 'C'],
    notesBlackSharp: ['C#', 'D#', 'F#', 'G#', 'A#'],
    notesBlackFlat: ['Db', 'Eb', 'Gb', 'Ab', 'Bb'],
    keysWhite: {
        width: '46.5',
        height: '180',
        y: '150',
        positions: ['14', '60.5', '107', '153.5', '200', '246.5', '293', '339.5']
    },
    keysBlack: {
        width: '40',
        height: '110',
        y: '150',
        positions: ['40.5', '87', '180', '226.5', '273']
    },
    textWhite: [
        '28.55 317.5',
        '73.76 317.5',
        '122.87 317.5',
        '169.45 317.5',
        '213.56 317.5',
        '260.57 317.5',
        '308.12 317.5',
        '354.05 317.5'
    ],
    textSharp: ['44.35 252.5', '89.56 252.5', '185.24 252.5', '229.36 252.5', '276.37 252.5'],
    textFlat: ['41.98 252.5', '91.09 252.5', '181.78 252.5', '228.83 252.5', '276.34 252.5']
};
