import { Helmet } from 'react-helmet';

function HelmetNextSplash() {
    return (
        <Helmet>
            <meta charset="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta http-equiv="X-UA-Compatible" content="ie=edge" />
            <meta
                name="description"
                content="Demo project of an image gallery with infinite scroll, using the Unsplash API, built with Next.js, deployed to Vercel"
            />
            <meta property="og:title" content="Scroll to Infinity" />
            <meta property="og:description" content="Scroll to Infinity" />
            <meta property="og:image" content="https://jsdisco.dev/next-splash/img/facebook-preview.jpg" />
            <meta property="og:url" content="https://jsdisco.dev/next-splash" />
            <meta name="twitter:title" content="Scroll to Infinity" />
            <meta name="twitter:description" content="Scroll to Infinity" />
            <meta name="twitter:image" content="https://jsdisco.dev/next-splash/img/twitter-preview.jpg" />
            <meta name="twitter:card" content="summary_large_image" />
            <link rel="icon" href="../favicon.png" />
            <link rel="apple-touch-icon" sizes="180x180" href="../apple-touch-icon-180x180.png" />
            <link rel="canonical" href="https://jsdisco.dev/next-splash" />
            <title>JSDISCO | Next-Splash</title>
        </Helmet>
    );
}

export default HelmetNextSplash;
