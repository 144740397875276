import HelmetMusiChords from '../helmets/HelmetMusiChords';
import Octocat from '../components/Octocat';
import WithTransition from '../components/WithTransition';

function ProjectMusiChords() {
    return (
        <WithTransition>
            <main>
                <HelmetMusiChords />
                <div className="section-indicator pink"></div>
                <div className="wrapper">
                    <div className="project-description">
                        <h1>MusiChords</h1>
                        <p className="lead">An Android app - combining a musical ear trainer and a chords dictionary</p>
                        <p className="google-play-btn">
                            <a
                                href="https://play.google.com/store/apps/details?id=com.jsdisco.musichords&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    alt="Get it on Google Play"
                                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                                />
                            </a>
                        </p>
                    </div>
                </div>
                <div className="wrapper">
                    <img className="img-project-preview" src="../img/project-preview-musichords.png" alt="project preview for MusiChords app" />
                </div>
                <div className="wrapper">
                    <div className="project-description">
                        <h2 className="border">About this App</h2>
                        <h3>Purpose</h3>
                        <p>This app combines a musical ear trainer and a chord dictionary.</p>
                        <p>
                            The ear trainer can be used to either train your relative pitch (identify intervals between two notes), and your ability
                            to recognise a chord by ear. You hear an interval or a chord, and have to find the correct answer from a list of options.
                            You can customise this list, and create and save your own exercises.
                        </p>
                        <p>
                            The chord dictionary is for a quick lookup of a chord. Just select root note and chord from a drop down, and the app shows
                            you a list of notes, chord intervals, midi keys, and a visual representation of the chord on a piano keyboard.
                        </p>
                        <h3>Motivation</h3>
                        <p>
                            After building my <a href="https://jsdisco.dev/guess-the-chord">guess-the-chord</a> game with React.js for web, I wanted
                            to build something similar as a native Android app. I've used it quite a lot, and my ability to recognise chords has
                            significantly improved. Just play a few rounds whenever you have some spare minutes.
                        </p>
                        <h3>Tech</h3>
                        <p>
                            The app was built with{' '}
                            <a href="https://developer.android.com/studio/" target="_blank" rel="noreferrer">
                                Android Studio
                            </a>{' '}
                            using{' '}
                            <a href="https://developer.android.com/jetpack/compose" target="_blank" rel="noreferrer">
                                Jetpack Compose
                            </a>{' '}
                            (written in{' '}
                            <a href="https://kotlinlang.org/" target="_blank" rel="noreferrer">
                                Kotlin
                            </a>
                            ).
                        </p>
                        <p>
                            Repository:{' '}
                            <a href="https://github.com/jsdisco/musichords" target="_blank" rel="noreferrer">
                                github.com/jsdisco/musichords
                            </a>
                        </p>
                        <h3>Privacy Policy</h3>
                        <p>
                            <a href="https://jsdisco.dev/musichords/privacypolicy.html" target="_blank" rel="noreferrer">
                                Privacy policy for the app
                            </a>
                        </p>
                    </div>
                </div>
                <Octocat url="https://github.com/jsdisco/musichords" />
            </main>
        </WithTransition>
    );
}

export default ProjectMusiChords;
