const projectsMainPage = [
    {
        route: '/musichords',
        link: null,
        imgPath: 'img/project-preview-musichords.png',
        title: 'MusiChords',
        description: 'Musical ear trainer/chords dictionary',
        additionalLink: null
    },
    {
        route: '/midi-interface',
        link: null,
        imgPath: 'img/project-preview-midi-interface.jpg',
        title: '2D MIDI Interface',
        description: 'Connect MIDI device & play',
        additionalLink: null
    },
    {
        route: null,
        link: 'https://pianoapi.jsdisco.dev',
        imgPath: 'img/project-preview-pianochordsapi.jpg',
        title: 'Piano Chords API',
        description: 'Published on',
        additionalLink: {
            link: 'https://rapidapi.com/jsdisco/api/piano-chords/',
            linkTitle: 'rapidapi.com'
        }
    },
    {
        route: '/next-splash',
        link: null,
        imgPath: 'img/project-preview-nextsplash.png',
        title: 'Next Splash',
        description: 'Scroll to Infinity',
        additionalLink: null
    },
    {
        route: '/guitar',
        link: null,
        imgPath: 'img/project-preview-guitar.jpg',
        title: 'Guitar',
        description: 'Guitar with chordfinder',
        additionalLink: null
    },
    {
        route: '/lil-helper',
        link: null,
        imgPath: 'img/project-preview-lilhelper.jpg',
        title: 'lil Helper',
        description: 'I use it every day',
        additionalLink: null
    },
    {
        route: '/musicmemory',
        link: null,
        imgPath: 'img/project-preview-musicmemory.jpg',
        title: 'Music Memory',
        description: 'Combining visuals and sound',
        additionalLink: null
    },
    {
        route: '/guess-the-chord',
        link: null,
        imgPath: 'img/project-preview-guessthechord.jpg',
        title: 'Guess the Chord',
        description: 'Train your musical ear',
        additionalLink: null
    },
    {
        route: '/hangman',
        link: null,
        imgPath: 'img/project-preview-hangman.jpg',
        title: 'HangMan',
        description: 'The classic word guessing game',
        additionalLink: null
    }
];

const blogs = [
    {
        title: 'Secure Authorisation',
        link: 'https://blog.jsdisco.dev/100daysofmern-day-42',
        description: 'How to implement secure authorisation on the frontend with httpOnly cookies instead of local storage.',
        imgPath: 'img/cover-100daysofmern42-secure-frontend-authorisation.jpg'
    },
    {
        title: 'Cubic-bezier functions in CSS',
        link: 'https://blog.jsdisco.dev/realistic-animations-with-css-timing-functions-bouncing-balls-pendulums',
        description: 'How to create realistic animations: CSS timing functions do the physics for you. With lots of codepen examples.',
        imgPath: 'img/cover-cubic-bezier-realistic-animations.jpg'
    },
    {
        title: 'How to build a Guitar with React',
        link: 'https://blog.jsdisco.dev/build-a-guitar-with-reactjs-part-1',
        description:
            'No images, no sound files, only JavaScript. A slightly easier version than the above linked project for a 5 part tutorial series.',
        imgPath: 'img/cover-build-react-guitar.jpg'
    }
];

export { projectsMainPage, blogs };
