import { Helmet } from 'react-helmet';

function HelmetMusicMemory() {
    return (
        <Helmet>
            <meta charset="UTF-8" />
            <meta name="viewport" content="width=device-width,initial-scale=1" />
            <meta http-equiv="X-UA-Compatible" content="ie=edge" />
            <meta name="description" content="memory (concentration) game variant with sounds to learn guitar and piano chords by @jsdisco" />
            <link rel="icon" href="../favicon.png" />
            <link rel="apple-touch-icon" sizes="180x180" href="../apple-touch-icon-180x180.png" />
            <link rel="canonical" href="https://jsdisco.dev/musicmemory" />
            <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap" rel="stylesheet" />
            <meta name="monetization" content="$ilp.uphold.com/8E734qp2PUJy" />
            <title>JSDISCO | music memory</title>
            <link href="./static/css/main.aa869652.chunk.css" rel="stylesheet" />
        </Helmet>
    );
}

export default HelmetMusicMemory;
