import React from 'react';

function IconSpeaker(){
    return (
        <svg id="icon-speaker" className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M441.63,451.51a23.29,23.29,0,0,1-16.79,8.23h-1a23.27,23.27,0,0,1-16.45-6.8l-3.05-3.07a23.26,23.26,0,0,1-1.36-31.4,247.78,247.78,0,0,0-4.09-323.74,23.24,23.24,0,0,1,.93-31.82l3.05-3.05A22.59,22.59,0,0,1,420,53a23.26,23.26,0,0,1,16.71,7.8c48.53,54.63,75.26,125,75.26,168.09v60.17c0,40.28-25,108.66-70.37,162.42m-96.12-71.86c-.58,0-1.11.06-1.68.06a23.23,23.23,0,0,1-16.45-6.8l-3.11-3.12A23.25,23.25,0,0,1,322,339.5a135.21,135.21,0,0,0-3.57-165.68,23.23,23.23,0,0,1,1.61-31.1l3.1-3.11a22.74,22.74,0,0,1,17.6-6.77,23.28,23.28,0,0,1,16.85,8.51,186.2,186.2,0,0,1,4.85,229,23.26,23.26,0,0,1-17,9.26M256.15,416a23.2,23.2,0,0,1-13.37,21,22.94,22.94,0,0,1-9.89,2.21,23.27,23.27,0,0,1-14.84-5.35l-116-96.1s-11.14-9.23-25.6-9.23H23.24A23.24,23.24,0,0,1,0,305.3V212.67a23.26,23.26,0,0,1,23.24-23.26H60.87s30.09,0,53.26-19.19L218.07,84.08A23.17,23.17,0,0,1,242.79,81c8.16,3.82,13.37,12,13.37,16.53V416Z"/>
        </svg>
    )
}

export default IconSpeaker;
