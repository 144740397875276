import { Helmet } from 'react-helmet';

function HelmetMidiInterface() {
    return (
        <Helmet>
            <meta charset="UTF-8" />
            <meta name="viewport" content="width=device-width,initial-scale=1" />
            <meta http-equiv="X-UA-Compatible" content="ie=edge" />
            <meta name="description" content="interface for midi device with isomorphic keyboard layout (linnstrument) by @jsdisco" />
            <link rel="icon" href="../favicon.png" />
            <link rel="apple-touch-icon" sizes="180x180" href="../apple-touch-icon-180x180.png" />
            <link rel="canonical" href="https://jsdisco.dev/midi-interface" />
            <link href="https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@400;700&display=swap" rel="stylesheet" />
            <meta name="monetization" content="$ilp.uphold.com/8E734qp2PUJy" />
            <title>JSDISCO | 2D MIDI interface</title>
        </Helmet>
    );
}

export default HelmetMidiInterface;
