import HelmetNextSplash from '../helmets/HelmetNextSplash';
import Octocat from '../components/Octocat';
import WithTransition from '../components/WithTransition';

function ProjectNextSplash() {
    return (
        <WithTransition>
            <main>
                <HelmetNextSplash />
                <div className="section-indicator pink"></div>
                <div className="wrapper">
                    <div className="project-description">
                        <h1>Next-Splash</h1>
                        <p className="lead">Demo project of an image gallery with infinite scroll</p>
                        <p>
                            <a href="https://next-splash.vercel.app/" target="_blank" rel="noreferrer">
                                https://next-splash.vercel.app/
                            </a>
                        </p>
                    </div>
                </div>
                <div className="wrapper">
                    <a href="https://next-splash.vercel.app/" target="_blank" rel="noreferrer">
                        <img className="img-project-preview" src="../img/project-preview-nextsplash.png" alt="project preview for next-splash" />
                    </a>
                </div>
                <div className="wrapper">
                    <div className="project-description">
                        <h2 className="border">About this Project</h2>
                        <h3>Motivation</h3>
                        <p>
                            This project was created as part of a job interview challenge. It was my first encounter with server-side rendered React,
                            AKA{' '}
                            <a href="https://nextjs.org/" target="_blank" rel="noreferrer">
                                Next.js
                            </a>
                            , and as such a fascinating experience. It uses the{' '}
                            <a href="https://unsplash.com/developers" target="_blank" rel="noreferrer">
                                Unsplash Photo API
                            </a>{' '}
                            to display a linear/grid layout of pictures, loaded in chunks of 10, to ensure a pleasant user experience upon scrolling.
                        </p>
                        <h3>Tech</h3>
                        <p>
                            The page was built with{' '}
                            <a href="https://nextjs.org/" target="_blank" rel="noreferrer">
                                Next.js
                            </a>
                            . Pictures are from the{' '}
                            <a href="https://unsplash.com/developers" target="_blank" rel="noreferrer">
                                Unsplash Photo API
                            </a>
                            . Deployed to{' '}
                            <a href="https://vercel.com/" target="_blank" rel="noreferrer">
                                Vercel
                            </a>
                            .
                        </p>
                        <p>
                            Repository:{' '}
                            <a href="https://github.com/jsdisco/next-splash" target="_blank" rel="noreferrer">
                                github.com/jsdisco/next-splash
                            </a>
                        </p>
                    </div>
                </div>
                <Octocat url="https://github.com/jsdisco/next-splash" />
            </main>
        </WithTransition>
    );
}

export default ProjectNextSplash;
