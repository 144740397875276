import React from 'react';

function IconLockOn(){
    return (
        <svg id="icon-lock-on" className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M450.75,249.79a32.91,32.91,0,0,0-24.17-10H415.27V158h-.08Q414.74,93,368.48,46.74,321.75,0,256,0T143.52,46.74Q96.79,93.47,96.78,159.21v80.63H85.43A34,34,0,0,0,51.3,274V478.65a34,34,0,0,0,34.12,34.12H426.57a34,34,0,0,0,34.12-34.12V269.22C460.7,264.48,457.38,256.42,450.75,249.79ZM165,221.61v-62.4q0-37.68,26.67-64.31T256,68.23q37.65,0,64.31,26.67T347,159.21c0,.28,0,.55,0,.83v79.8H183.24S165,239.83,165,221.61Z"/>
        </svg>
    )
}

export default IconLockOn;
