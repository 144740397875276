const dancefloorConfig = {
    intro: [
        null,
        null,
        null,
        null,
        88,
        null,
        null,
        null,
        93,
        null,
        null,
        null,
        84,
        null,
        null,
        81,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        72,
        null,
        null,
        null,
        77
    ],
    projects: [
        89,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        94,
        null,
        null,
        null,
        85,
        null,
        null,
        82,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        73,
        null,
        null,
        null,
        78
    ],
    blog: [
        90,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        95,
        null,
        null,
        null,
        86,
        null,
        null,
        83,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        74,
        null,
        null,
        null,
        79
    ],
    contact: [
        91,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        96,
        null,
        null,
        null,
        87,
        null,
        null,
        84,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        75,
        null,
        null,
        null,
        80
    ]
};

function Dancefloor({ section }) {
    return (
        <div className={`dancefloor ${section}`}>
            {dancefloorConfig[section].map((value, i) => (
                <div key={`${section}-${i}`} className={value !== null ? 'play' : ''}></div>
            ))}
        </div>
    );
}

export default Dancefloor;
