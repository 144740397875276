import React from 'react';

function IconLightsSingle(){
    return (
        <svg id="icon-lights-single" className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M490,247.43c-41.4-11.64-86.11-33.44-118.91-63.05a309.47,309.47,0,0,1-78-111.28L273.08,24.33c-2.55-6.19-10.64-10.46-19.84-10.46S236,18.14,233.4,24.33L213.33,73.09a309.47,309.47,0,0,1-78,111.28A286,286,0,0,1,22,247.43c-7.72,2.17-12.83,7.38-13,13.22s4.74,11.15,12.37,13.5l6.42,2C110,301.34,177.13,363.47,212,446.58l21.39,50.92c2.59,6.16,10.65,10.39,19.81,10.39s17.22-4.23,19.83-10.39l21.39-50.92C329.37,363.47,402,301.34,484.21,276.11l6.42-2c7.62-2.34,12.5-7.66,12.37-13.49S497.76,249.6,490,247.43Z"/>
        </svg>
    )
}

export default IconLightsSingle;
