import { useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import MainPage from './pages/MainPage';
import ProjectMusiChords from './pages/ProjectMusiChords';
import ProjectMidiInterface from './pages/ProjectMidiInterface';
import ProjectNextSplash from './pages/ProjectNextSplash';
import ProjectGuitar from './pages/ProjectGuitar';
import ProjectLilHelper from './pages/ProjectLilHelper';
import ProjectMusicMemory from './pages/ProjectMusicMemory';
import ProjectGuessTheChord from './pages/ProjectGuessTheChord';
import ProjectHangman from './pages/ProjectHangman';

import Header from './components/Header';
import Nav from './components/Nav';
import Footer from './components/Footer';

import './css/index.css';

function App() {
    const [activeSection, setActiveSection] = useState(0);
    const [currScrollPos, setCurrScrollPos] = useState(0);

    const location = useLocation();

    function updateActiveSection(activeIndex) {
        setActiveSection(activeIndex);
    }

    function rememberScrollPos(pos) {
        setCurrScrollPos(pos);
    }

    return (
        <>
            <Header />
            <Nav activeSection={activeSection} />
            <AnimatePresence mode="wait">
                <Routes key={location.pathname} location={location}>
                    <Route path="/musichords" element={<ProjectMusiChords />} />
                    <Route path="/midi-interface" element={<ProjectMidiInterface />} />
                    <Route path="/next-splash" element={<ProjectNextSplash />} />
                    <Route path="/guitar" element={<ProjectGuitar />} />
                    <Route path="/lil-helper" element={<ProjectLilHelper />} />
                    <Route path="/musicmemory" element={<ProjectMusicMemory />} />
                    <Route path="/guess-the-chord" element={<ProjectGuessTheChord />} />
                    <Route path="/hangman" element={<ProjectHangman />} />
                    <Route
                        path="/"
                        element={
                            <MainPage updateActiveSection={updateActiveSection} currScrollPos={currScrollPos} rememberScrollPos={rememberScrollPos} />
                        }
                    />
                </Routes>
            </AnimatePresence>
            <Footer />
        </>
    );
}

export default App;
