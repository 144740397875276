import React from 'react';

function IconLockOff(){
    return (
        <svg id="icon-lock-off" className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <rect fill="none" x="51.2" width="409.59" height="513"/>
            <path d="M450.84,249.9a33,33,0,0,0-24.19-10H183s-18,0-18-18V159.28q0-37.69,26.67-64.36T256,68.26q37.69,0,64.34,26.67T347,159.28A23.08,23.08,0,0,0,369.77,182h22.77a23.08,23.08,0,0,0,22.75-22.75q0-65.78-46.76-112.54T256,0Q190.23,0,143.47,46.74T96.7,159.28v80.66H85.33A34,34,0,0,0,51.2,274.07v204.8A34,34,0,0,0,85.33,513H426.65a34,34,0,0,0,34.15-34.13V269.32c0-4.73-3.32-12.8-10-19.43"/>
        </svg>
    )
}

export default IconLockOff;
