import { useLocation, NavLink } from 'react-router-dom';

import imgJS from '../img/js.svg';
import imgDisco from '../img/disco_font.jpg';

function Header() {
    const location = useLocation();

    return (
        <header>
            <div className={location.pathname === '/' ? 'section-indicator yellow' : 'section-indicator pink'}></div>
            <div className="wrapper">
                <NavLink to="/">
                    <div className="logo">
                        <img src={imgJS} alt="" className="header-img1" />
                        <img src={imgDisco} alt="" className="header-img2" />
                    </div>
                </NavLink>
            </div>
        </header>
    );
}

export default Header;
