import { Helmet } from 'react-helmet';

function HelmetLilHelper() {
    return (
        <Helmet>
            <meta charset="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta http-equiv="X-UA-Compatible" content="ie=edge" />
            <meta name="description" content="An Android App by @jsdisco" />
            <meta property="og:title" content="My little helper app for everyday tasks" />
            <meta property="og:description" content="My little helper app for everyday tasks" />
            <meta property="og:image" content="https://jsdisco.dev/lil-helper/img/facebook-preview.jpg" />
            <meta property="og:url" content="https://jsdisco.dev/lil-helper" />
            <meta name="twitter:title" content="My little helper app for everyday tasks" />
            <meta name="twitter:description" content="My little helper app for everyday tasks" />
            <meta name="twitter:image" content="https://jsdisco.dev/lil-helper/img/twitter-preview.jpg" />
            <meta name="twitter:card" content="summary_large_image" />
            <link rel="icon" href="../favicon.png" />
            <link rel="apple-touch-icon" sizes="180x180" href="../apple-touch-icon-180x180.png" />
            <link rel="canonical" href="https://jsdisco.dev/lil-helper" />
            <meta name="monetization" content="$ilp.uphold.com/8E734qp2PUJy" />
            <title>JSDISCO | lilHelper</title>
        </Helmet>
    );
}

export default HelmetLilHelper;
