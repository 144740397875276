import { useState, forwardRef } from 'react';

import Dancefloor from './Dancefloor';

const Contact = forwardRef((props, ref) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [validity, setValidity] = useState({ name: false, email: false, subject: false, message: false });
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [status, setStatus] = useState('');
    const [feedback, setFeedback] = useState('');

    function validate(e) {
        setValidity(prev => {
            const newValidity = { ...prev };
            newValidity[e.target.name] = e.target.validity.valid;

            setIsSubmitDisabled(!Object.values(newValidity).every(val => !!val));

            return newValidity;
        });
    }

    function handleInput(e) {
        if (e.target.name === 'name') {
            setName(e.target.value);
        } else if (e.target.name === 'email') {
            setEmail(e.target.value);
        } else if (e.target.name === 'subject') {
            setSubject(e.target.value);
        } else if (e.target.name === 'message') {
            setMessage(e.target.value);
        }
        validate(e);
    }

    const handleSubmit = async e => {
        e.preventDefault();
        setFeedback('Sending your message...');
        let result;

        const mailContent = { name, email, subject, message };
        try {
            const response = await fetch('/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(mailContent)
            });
            result = await response.json();
            if (result.status === 'success') {
                setStatus('success');
                setFeedback('Message successfully sent!');
            } else {
                setStatus('failure');
                setFeedback('Message could not be sent, please try again');
            }
        } catch (e) {
            result = { status: e };
            setStatus('failure');
            setFeedback('Message could not be sent, please try again');
        }
    };

    function closeFeedback() {
        setStatus('');
        setFeedback('');
    }

    return (
        <section id="contact" ref={ref}>
            <div className="section-indicator green"></div>
            <div className="wrapper">
                <Dancefloor section="contact" />
            </div>
            <div className="wrapper">
                <div className="contact-intro">
                    <div>
                        <h2>
                            <span>Contact</span>
                        </h2>
                        <p className="lead">Get in touch...</p>
                    </div>
                    <img src="img/jsdisco_avatar.jpg" alt="my avatar" />
                </div>
                <p>
                    Hi, I'm Maren. Full stack web and mobile app developer with background in science, currently based in Germany. Passionate about
                    coding, chess, music, riding my bike, reading, writing, yoga.
                </p>
                <p>
                    Interested in collaborating? Do you have any questions? Just want to say hi? Reach out on social media or fill out the form below,
                    I'll get back within 1-2 days.
                </p>
                <form id="contact-form" action="" method="POST" name="contact" onInput={handleInput} onSubmit={handleSubmit}>
                    <label htmlFor="input-name">
                        <input type="text" id="input-name" name="name" spellCheck="false" minLength="2" maxLength="60" placeholder="Jon" required />
                        <span>Name</span>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 17.2C7.5 17.2 5.29 15.92 4 14C4.03 12 8 10.9 10 10.9C12 10.9 15.97 12 16 14C14.71 15.92 12.5 17.2 10 17.2ZM10 3C10.7956 3 11.5587 3.31607 12.1213 3.87868C12.6839 4.44129 13 5.20435 13 6C13 6.79565 12.6839 7.55871 12.1213 8.12132C11.5587 8.68393 10.7956 9 10 9C9.20435 9 8.44129 8.68393 7.87868 8.12132C7.31607 7.55871 7 6.79565 7 6C7 5.20435 7.31607 4.44129 7.87868 3.87868C8.44129 3.31607 9.20435 3 10 3V3ZM10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 4.47 15.5 0 10 0Z" />
                        </svg>
                    </label>
                    <label htmlFor="input-email">
                        <input type="email" id="input-email" name="email" spellCheck="false" maxLength="40" placeholder="jon@doe.com" required />
                        <span>Email (will never be shared anywhere)</span>
                        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 4L10 9L2 4V2L10 7L18 2V4ZM18 0H2C0.89 0 0 0.89 0 2V14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H18C18.5304 16 19.0391 15.7893 19.4142 15.4142C19.7893 15.0391 20 14.5304 20 14V2C20 0.89 19.1 0 18 0Z" />
                        </svg>
                    </label>
                    <label htmlFor="input-subject">
                        <input
                            type="text"
                            id="input-subject"
                            name="subject"
                            spellCheck="false"
                            minLength="2"
                            maxLength="40"
                            placeholder="Cake"
                            required
                        />
                        <span>Subject</span>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 7H9V5H11V7ZM11 15H9V9H11V15ZM10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0V0Z" />
                        </svg>
                    </label>
                    <label htmlFor="input-message">
                        <textarea
                            id="input-message"
                            name="message"
                            rows="8"
                            spellCheck="false"
                            minLength="20"
                            maxLength="400"
                            placeholder="Hi Maren, ..."
                            required
                        ></textarea>
                        <span>Message</span>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V20L4 16H18C18.5304 16 19.0391 15.7893 19.4142 15.4142C19.7893 15.0391 20 14.5304 20 14V2C20 1.46957 19.7893 0.960859 19.4142 0.585786C19.0391 0.210714 18.5304 0 18 0ZM6 12H4V10H6V12ZM6 9H4V7H6V9ZM6 6H4V4H6V6ZM13 12H8V10H13V12ZM16 9H8V7H16V9ZM16 6H8V4H16V6Z" />
                        </svg>
                    </label>
                    <div className="form-submit">
                        <button type="submit" className="index-btn btn-green" disabled={isSubmitDisabled}>
                            Send
                        </button>
                    </div>
                    <p className={`feedback ${status}`}>
                        <span>{feedback}</span>
                        <span onClick={closeFeedback}>{feedback !== '' && 'x'}</span>
                    </p>
                </form>
            </div>
        </section>
    );
});

export default Contact;
