import HelmetGuitar from '../helmets/HelmetGuitar';
import Octocat from '../components/Octocat';
import Guitar from '../projects/projectGuitar/Guitar';
import WithTransition from '../components/WithTransition';

function ProjectGuitar() {
    return (
        <WithTransition>
            <main>
                <HelmetGuitar />
                <div className="section-indicator pink"></div>
                <div className="wrapper">
                    <div className="project-description">
                        <h1>Virtual Guitar</h1>
                        <p className="lead">Online guitar with chord finder</p>
                    </div>
                </div>
                <Guitar />
                <div className="wrapper">
                    <div className="project-description">
                        <h2 className="border">About this project</h2>
                        <h3>How to play</h3>
                        <p>You can click/tap on the fretboard to play single notes, or hover/click the bridge to play multiple strings.</p>
                        <p>
                            Playing on the fretboard will mark the frets, and playing on the bridge will play the strings as they are fretted. The
                            arrow button on the bridge toggles the current strum direction (down or up). Strings can be muted with the buttons on the
                            far left of the fretboard.
                        </p>
                        <h3>Chord finder</h3>
                        <p>If the currently fretted strings match a chord, the chord name(s) are displayed in the upper left.</p>
                        <p>
                            The chord finder is a work in progress. At the moment, the notation doesn't always follow conventions for naming guitar
                            chords.
                        </p>
                        <h3>Motivation</h3>
                        <p>
                            Coming from a musical background that is strongly influenced by piano, I wanted to explore guitars in more detail,
                            especially how to build a realistic fretboard layout.
                        </p>
                        <p>Coding the chord finder is a particularly interesting challenge, requiring a deep dive into musical theory.</p>
                        <h3>Tech</h3>
                        <p>
                            The app was built with{' '}
                            <a href="https://reactjs.org/" target="_blank" rel="noreferrer">
                                ReactJS
                            </a>
                            . Sounds are created with{' '}
                            <a href="https://github.com/keithwhor/audiosynth" target="_blank" rel="noreferrer">
                                AudioSynth
                            </a>
                            .
                        </p>
                        <p>
                            Repository:{' '}
                            <a href="https://github.com/jsdisco/projects-guitar" target="_blank" rel="noreferrer">
                                github.com/jsdisco/projects-guitar
                            </a>
                        </p>
                        <h3>Blog</h3>
                        <p>A detailed description of how to build this project can be found in a 5-part-series of tutorial articles on my blog:</p>
                        <p>
                            <a href="https://blog.jsdisco.dev/build-a-guitar-with-reactjs-part-1" target="_blank" rel="noreferrer">
                                Build a Guitar with Reactjs (1): Intro, setup and guitar body
                            </a>
                        </p>
                        <p>
                            <a href="https://blog.jsdisco.dev/build-a-guitar-with-reactjs-part-2" target="_blank" rel="noreferrer">
                                Build a Guitar with Reactjs (2): Guitar Sounds
                            </a>
                        </p>
                        <p>
                            <a href="https://blog.jsdisco.dev/build-a-guitar-with-reactjs-part-3" target="_blank" rel="noreferrer">
                                Build a Guitar with Reactjs (3): Fretboard-SVG
                            </a>
                        </p>
                        <p>
                            <a href="https://blog.jsdisco.dev/build-a-guitar-with-reactjs-part-4" target="_blank" rel="noreferrer">
                                Build a Guitar with Reactjs (4): The Mute Buttons
                            </a>
                        </p>
                        <p>
                            <a href="https://blog.jsdisco.dev/build-a-guitar-with-reactjs-part-5" target="_blank" rel="noreferrer">
                                Build a Guitar with Reactjs (5): The Final Fretboard
                            </a>
                        </p>
                    </div>
                </div>
                <Octocat url="https://github.com/jsdisco/projects-guitar" />
            </main>
        </WithTransition>
    );
}

export default ProjectGuitar;
