function BlogCard({ blog }) {
    return (
        <div className="blog-card">
            <h3>
                <span>{blog.title}</span>
                <a href={blog.link} target="_blank" rel="noreferrer">
                    <img src="img/new-window-blog.svg" alt="click to visit blog" width="36" height="36" />
                </a>
            </h3>
            <p>{blog.description}</p>
            <a href={blog.link} target="_blank" rel="noreferrer">
                <img src={blog.imgPath} alt="blog-cover" />
            </a>
        </div>
    );
}

export default BlogCard;
