import { Helmet } from 'react-helmet';

function HelmetHangman() {
    return (
        <Helmet>
            <meta charset="UTF-8" />
            <meta name="viewport" content="width=device-width,initial-scale=1" />
            <meta http-equiv="X-UA-Compatible" content="ie=edge" />
            <meta name="description" content="a word guessing game / hangman clone by @jsdisco" />
            <link rel="icon" href="../favicon.png" />
            <link rel="apple-touch-icon" sizes="180x180" href="../apple-touch-icon-180x180.png" />
            <link href="https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap" rel="stylesheet" />
            <link rel="canonical" href="https://jsdisco.dev/hangman" />
            <meta name="monetization" content="$ilp.uphold.com/8E734qp2PUJy" />
            <title>JSDISCO | Hangman</title>
            <link href="./static/css/main.16b08c49.chunk.css" rel="stylesheet" />
        </Helmet>
    );
}

export default HelmetHangman;
