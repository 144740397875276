import HelmetGuessTheChord from '../helmets/HelmetGuessTheChord';
import Octocat from '../components/Octocat';
import GuessTheChord from '../projects/projectGuessTheChord/GuessTheChord';
import WithTransition from '../components/WithTransition';

function ProjectGuessTheChord() {
    return (
        <WithTransition>
            <main>
                <HelmetGuessTheChord />
                <div className="section-indicator pink"></div>
                <div className="wrapper">
                    <div className="project-description">
                        <h1>Guess-The-Chord</h1>
                        <p className="lead">A game to train your ability to recognise a chord name by ear</p>
                    </div>
                </div>
                <GuessTheChord />
                <div className="wrapper">
                    <div className="project-description">
                        <h2 className="border">About the Game</h2>
                        <h3>How to play</h3>
                        <p>
                            After starting a new round, click on the play buttons to hear the chord with either piano or guitar sounds. By default,
                            all notes play at once, but the delay between the notes can be increased, to make it easier to identify single notes.
                        </p>
                        <p>You can choose in the settings which chords/roots should be included in the game.</p>
                        <p>
                            You can play the sound as many times as you like. Once you've found the correct chord name, all chord buttons turn into
                            play buttons, so you can compare the sounds of different chords, before proceeding to the next round.
                        </p>
                        <p>
                            After completing a few rounds, you can check the statistics view by clicking the button in the upper right. The game will
                            keep track of the average number of tries for each round, and which chords were identified with most/least certainty.
                        </p>
                        <h3>Motivation</h3>
                        <p>
                            I built this game to sharpen my ear. My desire was to learn the most relevant chords, understand the feelings they rise
                            when they transition into each other, and being able to name them.
                        </p>
                        <h3>Tech</h3>
                        <p>
                            The game was built with{' '}
                            <a href="https://reactjs.org/" target="_blank" rel="noreferrer">
                                ReactJS
                            </a>
                            . Chords are based on{' '}
                            <a href="https://github.com/tombatossals/chords-db" target="_blank" rel="noreferrer">
                                chords-db
                            </a>
                            , the instrument sounds are created with{' '}
                            <a href="https://surikov.github.io/webaudiofont/" target="_blank" rel="noreferrer">
                                WebAudioFont
                            </a>
                            .
                        </p>
                        <p>
                            Repository:{' '}
                            <a href="https://github.com/jsdisco/projects-guessthechord" target="_blank" rel="noreferrer">
                                github.com/jsdisco/projects-guessthechord
                            </a>
                        </p>
                    </div>
                </div>
                <Octocat url="https://github.com/jsdisco/projects-guessthechord" />
            </main>
        </WithTransition>
    );
}

export default ProjectGuessTheChord;
