import { forwardRef } from 'react';

import ProjectCard from './ProjectCard';
import Dancefloor from './Dancefloor';

import { projectsMainPage } from '../data/data';

const Projects = forwardRef((props, ref) => {
    return (
        <section id="projects" ref={ref}>
            <div className="section-indicator pink"></div>
            <div className="wrapper">
                <Dancefloor section="projects" />
            </div>
            <div className="wrapper">
                <h2>
                    <span>Projects</span>
                </h2>
                <p className="lead">A collection of some of my projects.</p>
                <p>Click on a project image for a more detailed description, the tools that were used, and a link to the source code.</p>

                <div className="project-cards">
                    {projectsMainPage.map(project => (
                        <ProjectCard key={`${project.title}`} project={project} rememberScrollPos={props.rememberScrollPos} />
                    ))}
                </div>
            </div>
        </section>
    );
});

export default Projects;
