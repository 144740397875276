import { Helmet } from 'react-helmet';

function HelmetIndex() {
    return (
        <Helmet>
            <meta charset="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta http-equiv="X-UA-Compatible" content="ie=edge" />
            <meta name="description" content="developer portfolio of @jsdisco" />
            <meta name="robots" content="index, follow" />
            <link rel="icon" href="favicon.png" />
            <link rel="apple-touch-icon" sizes="180x180" href="apple-touch-icon-180x180.png" />
            <link rel="canonical" href="https://jsdisco.dev" />

            <title>JSDISCO</title>
        </Helmet>
    );
}

export default HelmetIndex;
