import { Helmet } from 'react-helmet';

function HelmetMusiChords() {
    return (
        <Helmet>
            <meta charset="UTF-8" />
            <meta name="viewport" content="width=device-width,initial-scale=1" />
            <meta http-equiv="X-UA-Compatible" content="ie=edge" />
            <meta name="description" content="MusiChords is an Android app by @jsdisco - combining a musical ear trainer and a chords dictionary" />
            <meta property="og:title" content="MusiChords is an Android app by @jsdisco - combining a musical ear trainer and a chords dictionary" />
            <meta
                property="og:description"
                content="MusiChords is an Android app by @jsdisco - combining a musical ear trainer and a chords dictionary"
            />
            <meta property="og:image" content="https://jsdisco.dev/musichords/img/facebook-preview.png" />
            <meta property="og:url" content="https://jsdisco.dev/musichords" />
            <meta name="twitter:title" content="MusiChords is an Android app by @jsdisco - combining a musical ear trainer and a chords dictionary" />
            <meta
                name="twitter:description"
                content="MusiChords is an Android app by @jsdisco - combining a musical ear trainer and a chords dictionary"
            />
            <meta name="twitter:image" content="https://jsdisco.dev/musichords/img/twitter-preview.png" />
            <meta name="twitter:card" content="summary_large_image" />
            <link rel="icon" href="../favicon.png" />
            <link rel="apple-touch-icon" sizes="180x180" href="../apple-touch-icon-180x180.png" />
            <link rel="canonical" href="https://jsdisco.dev/musichords" />
            <meta name="monetization" content="$ilp.uphold.com/8E734qp2PUJy" />
            <title>JSDISCO | MusiChords</title>
        </Helmet>
    );
}

export default HelmetMusiChords;
