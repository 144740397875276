import HelmetMidiInterface from '../helmets/HelmetMidiInterface';
import Octocat from '../components/Octocat';
import MidiInterface from '../projects/projectMidiInterface/MidiInterface';
import WithTransition from '../components/WithTransition';

function ProjectMidiInterface() {
    return (
        <WithTransition>
            <main>
                <HelmetMidiInterface />
                <div className="section-indicator pink"></div>
                <div className="wrapper">
                    <div className="project-description">
                        <h1>2D MIDI Interface</h1>
                        <p className="lead">Because it's awesome</p>
                    </div>
                </div>
                <MidiInterface />
                <div className="wrapper">
                    <div className="project-description">
                        <h2 className="border">About this project</h2>
                        <h3>Introduction</h3>
                        <p>I built this project as requested by a client, and it was a great pleasure to work on it.</p>
                        <p>
                            It uses the{' '}
                            <a href="https://www.smashingmagazine.com/2018/03/web-midi-api/" target="_blank" rel="noreferrer">
                                Web MIDI API
                            </a>{' '}
                            to process inputs from a MIDI device in the browser. This feature is very recent and still in experimental status, and not
                            all browsers are supporting it yet (
                            <a href="https://developer.mozilla.org/en-US/docs/Web/API/MIDIAccess" target="_blank" rel="noreferrer">
                                MDN documentation
                            </a>
                            ).
                        </p>
                        <h3>2D controller layout</h3>
                        <p>
                            The most intriguing part of this project is the MIDI controller layout, based on an{' '}
                            <a href="https://en.wikipedia.org/wiki/Isomorphic_keyboard" target="_blank" rel="noreferrer">
                                isomorphic keyboard
                            </a>
                            . Moving horizontally is the same as on a piano keyboard, each step is one half tone. Moving vertically, each step is five
                            half tones.
                        </p>
                        <p>
                            This allows to play much bigger intervals with one hand (spans of two or three octaves are easily within reach), so the
                            player isn't as limited as they are on a linear piano keyboard.
                        </p>
                        <p>
                            Another great advantage is that each chord has the same shape. On a piano, a major chord in C consists of three white keys
                            (C-E-G). The same chord in D is white-black-white (D-F#-A). Unless a player has perfect pitch, they have to memorise the
                            shape of a chord for all 12 keys, or need enough exercise so it's stored in their muscle memory.
                        </p>
                        <p>
                            The grid layout allows to effortlessly transpose between different keys, just by moving the hand to a different location,
                            without changing the shape. In that regard, the grid is closer to a guitar than to a piano, but much more flexible,
                            because programmatically changing the assignment of a button to a certain note is much easier than equipping the guitar
                            with a whole new set of strings.
                        </p>
                        <h3>How to use the interface</h3>
                        <p>
                            After plugging in the MIDI controller, choose the layout that best fits your device from the menu in the upper right. The
                            default is an 8x8 grid (to be used with an{' '}
                            <a href="https://www.ableton.com/en/products/controllers/launchpad/" target="_blank" rel="noreferrer">
                                Ableton Launchpad
                            </a>{' '}
                            for example), starting with note B. The styles (light/dark mode, button background colour, primary highlight colour,
                            labels on/off) are customisable as well.
                        </p>
                        <p>
                            Playing on the controller will highlight all buttons with the same note (midiKey) on the grid and the corresponding piano
                            key - and vice versa.
                        </p>
                        <p>
                            The lock was part of the client's specification. It allows to highlight multiple piano keys with a mouseclick (deselect by
                            clicking again), making the corresponding grid buttons light up. Since one piano key maps to potentially multiple grid
                            buttons, the lock disables switching between multi/single lights mode (more explanation below).
                        </p>
                        <p>
                            Playing three or more different notes will trigger a custom chordfinder to analyse the intervals and traverse a tree of
                            possible matches, which are then displayed at the top.
                        </p>
                        <h3 id="multiple-channel-setup">Single Light Mode</h3>
                        <p>
                            To highlight only the exact button that was pressed on the controller, the device needs to send additional information to
                            the interface along with the midiKey. The button in the lower left (available after clicking "Start") illustrates how the
                            grid is divided into regions, representing different midi channels. The C-3 button (midiKey 60) appears twice on the grid,
                            either in channel 1 (command 144) or channel 2 (command 145).
                        </p>
                        <p>
                            To help adjusting the controller layout, the command and midiKey that were received by the interface are printed out at
                            the bottom.
                        </p>
                        <h3>Tech</h3>
                        <p>
                            The interface was written in{' '}
                            <a href="https://reactjs.org/" target="_blank" rel="noreferrer">
                                ReactJS
                            </a>
                            . Sounds are produced using{' '}
                            <a href="https://surikov.github.io/webaudiofont/" target="_blank" rel="noreferrer">
                                WebAudioFont
                            </a>
                            .
                        </p>
                        <p>Browsers supporting Web MIDI API: Chrome, Opera, Edge</p>
                        <p>
                            Repository:{' '}
                            <a href="https://github.com/jsdisco/projects-midi-interface" target="_blank" rel="noreferrer">
                                github.com/jsdisco/projects-midi-interface
                            </a>
                        </p>
                    </div>
                </div>
                <Octocat url="https://github.com/jsdisco/projects-midi-interface" />
            </main>
        </WithTransition>
    );
}

export default ProjectMidiInterface;
