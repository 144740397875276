import React from 'react';

function IconLightsAll(){
    return (
        <svg id="icon-lights-all" className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M503.09,248.94c-28.44-10.33-59.17-29.68-81.71-56-23-26.8-41.5-61-53.57-98.78L354,50.95c-1.76-5.51-7.32-9.29-13.65-9.29s-11.87,3.78-13.63,9.29L312.93,94.21c-12,37.82-30.58,72-53.55,98.78-22.54,26.28-49.48,45.63-77.93,56-5.3,1.93-8.82,6.55-8.91,11.73s3.27,9.9,8.51,12l4.4,1.76c56.48,22.38,102.63,77.53,126.62,151.28l14.69,45.19c1.79,5.46,7.33,9.21,13.62,9.21s11.84-3.75,13.62-9.21l14.71-45.19c24-73.75,73.93-128.9,130.4-151.3l4.42-1.75c5.23-2.08,8.58-6.79,8.49-12S508.4,250.87,503.09,248.94Z"/>
            <path d="M20.08,155.84l2.21.88c26.41,10.47,50,36.64,61.45,71.81l7.38,22.66c1.78,5.48,7.32,9.23,13.62,9.23s11.82-3.75,13.62-9.23l7.38-22.66c11.44-35.17,35-61.35,61.44-71.81l2.21-.88c5.24-2.08,8.6-6.79,8.51-12s-3.6-9.81-8.91-11.73c-27.17-9.87-52.15-37.32-63.68-73.44L118.36,37c-1.76-5.49-7.32-9.29-13.63-9.29S92.85,31.49,91.09,37L84.17,58.69C72.65,94.8,47.66,122.26,20.48,132.13a12.43,12.43,0,0,0-.4,23.71"/>
            <path d="M130.49,383.51c-17.68-6.42-36.58-24.56-44.22-48.55l-4.82-15.09c-1.76-5.51-7.32-9.29-13.63-9.29s-11.88,3.78-13.65,9.29L49.36,335c-7.66,24-22.77,42.13-40.44,48.55C3.6,385.43.09,390.06,0,395.24a12.84,12.84,0,0,0,8.5,12l1.54.61c16.95,6.72,31.53,24.47,39,47.49l5.14,15.77c1.78,5.48,7.32,9.23,13.62,9.23s11.82-3.75,13.62-9.23l5.12-15.77c7.49-23,25.86-40.77,42.8-47.49l1.54-.61c5.24-2.08,8.6-6.79,8.51-12S135.81,385.43,130.49,383.51Z"/>
        </svg>
    )
}

export default IconLightsAll;
