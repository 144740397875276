import { NavLink } from 'react-router-dom';

function ProjectCard({ project, rememberScrollPos }) {
    return (
        <div className="project-card">
            {project.link === null ? (
                <NavLink to={project.route} onClick={() => rememberScrollPos(window.pageYOffset)}>
                    <img src={project.imgPath} alt={`project-preview ${project.title}, link to details of this project`} />
                </NavLink>
            ) : (
                <a href={project.link} target="_blank" rel="noreferrer">
                    <img src={project.imgPath} alt={`project-preview ${project.title}, link to details of this project`} />
                </a>
            )}

            <div className="project-card-title">
                <h3>{project.title}</h3>
                <p>
                    {project.description}{' '}
                    {project.additionalLink !== null && (
                        <a href={project.additionalLink.link} target="_blank" rel="noreferrer">
                            {project.additionalLink.linkTitle}
                        </a>
                    )}
                </p>
            </div>
        </div>
    );
}

export default ProjectCard;
