import { forwardRef } from 'react';

import BlogCard from './BlogCard';
import Dancefloor from './Dancefloor';

import { blogs } from '../data/data';

const Blog = forwardRef((props, ref) => {
    return (
        <section id="blog" ref={ref}>
            <div className="section-indicator cyan"></div>
            <div className="wrapper">
                <Dancefloor section="blog" />
            </div>
            <div className="wrapper">
                <h2>
                    <span>Blog</span>
                    <a href="https://blog.jsdisco.dev/" target="_blank" rel="noreferrer">
                        <img src="img/new-window-blog.svg" alt="click to visit blog" width="36" height="36" />
                    </a>
                </h2>
                <p className="lead">
                    I write on{' '}
                    <a href="https://blog.jsdisco.dev/" target="_blank" rel="noreferrer">
                        Hashnode
                    </a>{' '}
                    about JavaScript (particularly the MERN stack), and various miscellaneous topics related to web and app development:
                </p>

                <div className="blog-collection">
                    {blogs.map(blog => (
                        <BlogCard key={blog.title} blog={blog} />
                    ))}
                </div>

                <div className="blog-link">
                    <a className="index-btn btn-cyan" href="https://blog.jsdisco.dev/" target="_blank" rel="noreferrer">
                        all articles
                    </a>
                </div>
            </div>
        </section>
    );
});

export default Blog;
